import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import { VideoPlayer } from '../Content/DailyDashboard/styles';

class VideoMaker extends React.Component {
  render() {
    const { video, height, updateUserPoints } = this.props;
    return(
      <VideoPlayer height={height}>
        <ReactPlayer
          url={video.video_url}
          controls
          onEnded={updateUserPoints}
          key={video?.video_url}
        />
      </VideoPlayer>
    );
  }
}

VideoMaker.propTypes = {
  video: PropTypes.object,
  height: PropTypes.string,
  updateUserPoints: PropTypes.func
};

export default VideoMaker;