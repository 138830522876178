import { EditButton } from '../Article/styles';
import styled from 'styled-components';

const VideoCreateButton = styled(EditButton)`
    margin: 20px 25px 0 0;
`;

const Button = styled.div`
width: 100px;
height: 35px;
margin: auto;
cursor: pointer;
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #9C9C9C;
text-align: center;
padding: 10px 0;
>img{
    width: 9px;
    height: 5px;
}
`;

export {VideoCreateButton, Button};