import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ChampionsCard, Layout, CardContainer } from '../AdventureCategories/styles';
import Waiting from '../Waiting';
import { getVideosLibrary, getWebinarLibrary } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import RecommendedVideos from '../RecommendedVideos/recommendedVideo';
import AdventurePointDashBoard from '../AdventurePointDashBoard';
import { HaederName, ImageIcon } from '../Journeys/styles';
import StyledDropDown from '../DropDown/dropDownFitness';
import { ImageUrlNew } from '../../utils/constants';
import { AdminButtons } from '../Article/styles';
import { NoComments } from '../SocialFeeds/styles';

class VideosLibrary extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      dropdown: 0,
      open: false
    }
  }
  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };
  componentDidMount() {
    const { history, fetchVideosLibrary, fetchWebinarVideoLibrary } = this.props;
    const arr = history.location.pathname.split('/');
    if (arr[2] === 'videos-library')
      fetchVideosLibrary(arr[arr.length - 1])
    else if (arr[2] === 'webinar')
      fetchWebinarVideoLibrary(arr[arr.length - 1]);

    // document.querySelector(".nav").style.display = "none";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { location, fetchVideosLibrary, fetchWebinarVideoLibrary } = this.props;
      const StringArray = location.pathname.split('/');
      if (StringArray[2] === 'videos-library')
        fetchVideosLibrary(StringArray[(StringArray.length - 1)])
      else if (StringArray[2] === 'webinar')
        fetchWebinarVideoLibrary(StringArray[(StringArray.length - 1)]);
    }
    // document.querySelector(".nav").style.display = "none";
  }

  showArticle = (id) => {
    const { history, location } = this.props;
    const currentLocation = location.pathname;
    const stringArray = location.pathname.split('/');
    let url = stringArray[2] === 'videos-library' ? 'fitness' : 'article';
    history.push(`${currentLocation}/${url}/${id}`);
  };

  showDropDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
      open: true
    })
  };

  getVideosAdventureDashboard = (videoLibrariesDetails) => (
    videoLibrariesDetails && videoLibrariesDetails.length > 0 ? videoLibrariesDetails.map((video, index) => (
      video && video.id ?
        <RecommendedVideos onClickFavoriteRibbon={this.props.onClickFavoriteRibbon} library={1} showArticle={this.showArticle} video={video} key={index} onHover={this.state.onHover} history={this.props.history} grid={0} link={'videos-library'} margin={index % 2 === 1 ? "0px" : '0px'} bottom={"25px"} width={'calc((100% / 2) - 12.5px)'} /> :
        <NoComments key={index}>{this.props.t("Videos Not Available")}</NoComments>
    ))
      : <ChampionsCard>{this.props.t("Videos Not Available")}</ChampionsCard>
  );

  addCategory = (categoryName, categories) => {
    let index = categories.findIndex((data) => data.category_name.toLowerCase() == categoryName.toLowerCase());
    return index != -1 ? categories[index].image : "test.png";
  };

  render() {
    const { videos, location, history, webinarVideos, t, videoLibraries, allowedRoles, createVideo } = this.props;
    const { dropdown } = this.state;
    const arr = history.location.pathname.split('/');
    const stringArray = location.pathname.split('/');

    if (stringArray[2] === 'videos-library') {
      if (!videos) {
        return <Waiting />
      }
    } else {
      if (!webinarVideos) {
        return <Waiting />
      }
    }
    const videoCategory = videoLibraries?.filter((category) =>
      category.available_videos > 0
    );
    const categoryName = stringArray[stringArray.length - 1];
    return (
      <Layout style={{ "margin-top": "-85px" }}>
        <div>
          {arr[2] === 'videos-library' ?
            <HaederName>
              <div>
                <div>
                  <img
                    src={`${ImageUrlNew}/${this.addCategory(categoryName, videoLibraries)}`}
                  />
                </div>
                <div>
                  <span>
                    {this.props.t(categoryName)} {t("Videos")}
                  </span>
                </div>
              </div>
              <ImageIcon > <img src="/public/images/NewDashboardV2/menuicon.png" alt="back-arrow" onClick={() => this.showDropDown()} />
              </ImageIcon>
            </HaederName>
            :
            <HaederName>
              <div>
                <div />
                <div>
                  <span>
                    {this.props.t(categoryName)} {t("Series")}
                  </span>
                </div>
              </div>
              <ImageIcon > <img src="/public/images/NewDashboardV2/menuicon.png" alt="back-arrow" onClick={() => this.showDropDown()} />
              </ImageIcon>
            </HaederName>
          }
          {dropdown !== 0 ?
            <StyledDropDown
              title={this.props.t("Adventures Categories")}
              MenuItems={videoCategory}
              isAdventurePage={true}
              history={history}
              onSelect={this.onSelect}
              isOpen={true}
            />
            : ''}
          <div style={{ "display": "flex" }}>
            <div style={{ "width": "67.1%" }}>
              <CardContainer>
                {arr[2] === 'videos-library' ?
                  videos && videos.length > 0 ?
                    this.getVideosAdventureDashboard(videos) : null
                  : webinarVideos && webinarVideos.length > 0 ?
                    this.getVideosAdventureDashboard(webinarVideos) : "Videos Not Available"}
              </CardContainer>
            </div>
            <div style={{ "width": "32.9%", "padding-left": "25px", "position": "relative", "bottom": "75px" }}>
              {createVideo &&
                <AdminButtons background={'#FD7175'} onClick={() => history.push('/education/create-video')}>
                  <div>
                    <img src="/public/images/NewDashboardV2/createvideo.png" alt="editvideo" />
                  </div>
                  <div>
                    {this.props.t("Create Video")}
                  </div>
                </AdminButtons>
              }
              {allowedRoles &&
                <AdminButtons background={'#69C2FF'} onClick={() => history.push('/education/create-video-category')}>
                  <div>
                    <img src="/public/images/NewDashboardV2/createjourney.png" alt="editvideo" />
                  </div>
                  <div>
                    {this.props.t("Create Fitness Category")}
                  </div>
                </AdminButtons>
              }
              <AdventurePointDashBoard />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

VideosLibrary.propTypes = {
  videos: PropTypes.array,
  fetchVideosLibrary: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchWebinarVideoLibrary: PropTypes.func.isRequired,
  webinarVideos: PropTypes.array,
  webinarCategory: PropTypes.array,
  t: PropTypes.func,
  onClickFavoriteRibbon: PropTypes.func,
  videoLibraries: PropTypes.array,
  role: PropTypes.string.isRequired,
  createVideo: PropTypes.bool,
  allowedRoles: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  videos: state.education.videos,
  webinarVideos: state.education.webinarVideos,
  videoLibraries: state.education.videoLibraries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideosLibrary: (categoryName) => dispatch(getVideosLibrary(categoryName)),
  fetchWebinarVideoLibrary: (categoryName) => dispatch(getWebinarLibrary(categoryName))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VideosLibrary)));
